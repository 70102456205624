import { graphql } from 'gatsby';
import React from 'react';
import Button from 'reactstrap/lib/Button';
import { BaseProps } from '../models/BaseProps';
import { GraphSFC } from '../models/GraphSFC';
import { renderLive, renderPreview } from '../utils/render';

export interface StandardPageProps extends BaseProps {
  title: string;
  rows: Row[];
}

export interface Row {
  columns: Column[];
}

export interface Column {
  title: string;
  content: string;
  image: Image;
}

export interface Image {
  image: any;
  alt: string;
}

export const StandardPage: React.SFC<StandardPageProps> = props => {
  console.log('props', props);

  return (
    <div>
      <h2 className="title is-size-3 has-text-weight-bold is-bold-light">{props.title}</h2>
      <Button color="danger">Standard page!</Button>
    </div>
  );
};

export const StandardPageLive: GraphSFC<StandardPageProps> = props => {
  return renderLive(props, p => <StandardPage {...p} />);
};

export const StandardPagePreview = props => {
  return renderPreview<StandardPageProps>(props, p => <StandardPage {...p} />);
};

export default StandardPageLive;

export const standardPageQuery = graphql`
  query StandardPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        rows {
          columns {
            title
            content
            img {
              file
              alt
            }
          }
        }
      }
    }
  }
`;
